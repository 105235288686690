<template>
  <v-expansion-panels focusable accordion flat>
    <v-expansion-panel>
      <v-expansion-panel-header class="panel">
        {{ "Solicitud" }}
      </v-expansion-panel-header>
      <v-expansion-panel-content v-for="(b, i) in bitacora.solicitud" :key="i">
        <v-card class="text-start">
          <v-card-text>
            <v-row>
              <v-col v-if="b.estado_anterior !== null" cols="12" md="6">
                <p>
                  Estado anterior:
                  <strong>{{ b.estado_anterior }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p>
                  Fecha:
                  <strong>{{
                    moment(b.fecha).format("DD/MM/YYYY hh:mm A")
                  }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p>
                  Estado actual: <strong>{{ b.nuevo_estado }}</strong>
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <p>
                  Usuario: <strong>{{ b.usuario }}</strong>
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header class="panel">
        {{ "Insumos" }}
      </v-expansion-panel-header>
      <v-expansion-panel-content v-for="(b, i) in bitacora.insumos" :key="i">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ b.nombre }}
            </v-expansion-panel-header>
            <v-expansion-panel-content v-for="(e, j) in b.estados" :key="j">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <p>
                        Estado: <strong>{{ e.nombre }}</strong>
                      </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p>
                        Fecha:
                        <strong>{{
                          moment(e.created_at).format("DD/MM/YYYY hh:mm A")
                        }}</strong>
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import moment from "moment-timezone";

export default {
  props: {
    bitacora: {
      type: Object,
      require: true,
    },
  },
};
</script>
<style scoped>
:deep(.v-expansion-panel--active > .v-expansion-panel-header) {
  background: #111c4e !important;
  color: white !important;
}
:deep(.v-expansion-panel--active .v-expansion-panel-header__icon i) {
  color: white !important;
}
</style>
