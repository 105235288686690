<template>
  <section class="mx-3">
    <div class="my-6 d-flex flex-column text-center text-sm-left">
      <span
        class="text-h6 secondary--text"
        v-text="'Solicitud de compra en línea'"
      />
      <span class="text-h5 secondary--text mt-2">
        {{
          selectedUnidad.id_tipo_unidad === 3 &&
          selectedUnidad.id_tipo_unidad !== 4
            ? selectedUnidad?.nombre
            : ""
        }}
        {{
          selectedUnidad.id_tipo_unidad == 4 &&
          selectedUnidad.id_tipo_unidad !== 3
            ? selectedUnidad?.nombre + " / " + compra?.unidad
            : ""
        }}</span
      >
    </div>
    <!-- ---- -->

    <v-row
      class="mb-8 mt-5"
      v-if="puedeAgregarInsumos && compra?.id_estado === 1"
    >
      <v-col cols="2">
        <v-btn
          block
          color="secondary"
          dark
          :to="{
            path: `/solicitud-compra-linea/${$route.params.id_compra_linea}/agregar-insumo`,
          }"
          >Agregar insumo</v-btn
        >
      </v-col>
    </v-row>

    <p class="text-h6 font-museo-sans primary--text mb-1">Listado de insumos</p>
    <data-table-component
      :headers="encabezado"
      :items="solicitudDetalle"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters2.total_rows"
      @paginar="paginar"
    >
      <template #[`item.correlativo`]="{ item }">
        {{
          firstElement(
            solicitudDetalle
              .map((col) => col.id)
              .reverse()
              .indexOf(item.id) + 1
          ) || ""
        }}
      </template>
      <template #[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.monto)
        }}
      </template>
      <template #[`item.total`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.total)
        }}
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-row class="ma-0 pa-0 pl-5" style="width: 80%">
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="editarDetalle(item)"
                  v-if="item.id_estado_solicitud === 1"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span> Editar detalle</span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="verDetalle(item)"
                  v-if="item.id_estado_solicitud > 1"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span> Ver detalle</span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="abrirDetalleEliminar(item)"
                  v-if="puedeAgregarInsumos && item.id_estado_solicitud === 1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar detalle</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </data-table-component>

    <v-row class="mt-7 mb-2">
      <v-col cols="12" sm="4" md="4">
        <div class="d-flex">
          <v-file-input
            v-if="puedeAgregarInsumos && !compra.documento_justificacion"
            label="Justificación de compra *"
            outlined
            accept=".pdf"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            v-model="documento"
            @blur="$v.documento.$touch"
            :error-messages="documentoError"
          ></v-file-input>
        </div>

        <v-btn
          v-if="compra.documento_justificacion"
          color="secondary"
          dark
          block
          @click="descargarArchivo(compra.documento_justificacion)"
        >
          Descargar justificación de compra
          <v-icon class="mx-2">mdi-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div
      v-if="
        (haveRole('ROLE_UFI') &&
          $store.state.compraLinea.compra.estado === 'Verificado por UCP') ||
        $store.state.compraLinea.compra.estado === 'Aprobado por UFI'
      "
      class="mt-5 mb-3"
    >
      <v-row>
        <v-col cols="7">
          <span class="text-h6 secondary--text">Información del pago</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="3">
          <v-select
            label="Forma de pago *"
            outlined
            v-model="id_pago"
            :error-messages="formaError"
            @blur="$v.id_pago.$touch"
            :items="pagos"
            item-text="nombre"
            item-value="id"
            :readonly="compra.id_estado === 5"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            outlined
            label="Pago *"
            v-model="pago"
            prepend-icon=""
            prepend-inner-icon="mdi-currency-usd"
            :error-messages="pagoError"
            @blur="$v.pago.$touch"
            @input="$v.pago.$touch"
            @change="formatPago()"
            :rules="[(v) => /^[0-9 .,]*$/.test(v) || 'Caracteres no válidos']"
            :readonly="compra.id_estado === 5"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="7">
          <v-btn
            v-if="infoPago && infoPago.comprobando"
            color="secondary"
            dark
            block
            @click="descargarArchivo(infoPago.comprobando)"
          >
            Descargar comprobante de pago
            <v-icon class="mx-2">mdi-download</v-icon>
          </v-btn>
          <v-file-input
            v-else
            label="Comprobante de pago *"
            outlined
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            accept=".pdf"
            v-model="comprobante"
            :error-messages="comprobanteError"
            @blur="$v.comprobante.$touch"
          ></v-file-input>
        </v-col>
      </v-row>
    </div>

    <v-row class="d-flex justify-center justify-sm-start">
      <v-btn
        class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
      <v-btn
        class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
        color="secondary"
        dark
        v-if="
          compra.id_estado === 1 &&
          (haveRoles([
            'ROLE_TECNICO_UNIDAD',
            'ROLE_UACI_TECNICO',
            'ROLE_UFI_TECNICO',
          ]) ||
            selectedUnidad.cargo[0].id == 7) &&
          !compra?.documento_justificacion
        "
        @click="abrirModalEnviar()"
        :loading="loading"
      >
        Enviar solicitud
      </v-btn>
      <v-btn
        class="button-extra t mt-4 mt-sm-0 mx-8 mx-sm-4"
        color="secondary"
        dark
        v-if="
          compra.id_estado === 1 &&
          !compra?.documento_justificacion &&
          haveRoles(['ROLE_JEFE_UNIDAD'] || selectedUnidad.cargo[0].id == 19)
        "
        @click="abrirModal()"
        :loading="loading"
      >
        Guardar
      </v-btn>

      <template v-if="handleBtnApprove">
        <v-btn
          :disabled="
            solicitudDetalle.length == 0 || !compra?.documento_justificacion
          "
          class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="green"
          dark
          @click="abrir()"
        >
          Aprobar compra
        </v-btn>
        <v-btn
          class="button-extra mt-4 mt-sm-0 mx-8 mx-sm-4"
          color="red"
          v-if="
            solicitudDetalle.length !== 0 && compra?.documento_justificacion
          "
          outlined
          @click="abrirM()"
        >
          Rechazar compra
        </v-btn>
      </template>
    </v-row>
    <!-- old validation-->

    <!-- (haveRoles(['ROLE_JEFE_UNIDAD', 'ROLE_UFI', 'ROLE_UACI']) ||
          selectedUnidad.cargo[0].id == 19) &&
        $store.state.compraLinea.compra.estado !== 'En borrador' -->
    <ConfirmationDialog
      :show="modalDelete"
      title="¿Está seguro de eliminar este insumo?"
      message="De confirmar la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalDelete = false"
      @confirm="eliminarDetalle()"
    />
    <ConfirmationDialog
      :show="modalEstadoA"
      title="¿Está seguro de aprobar esta solicitud?"
      message="De confirmar la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEstadoA = false"
      @confirm="aprobar()"
    />
    <ConfirmationDialog
      :show="modalEnvio"
      title="¿Está seguro de enviar esta solicitud?"
      message="De confirmar la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEnvio = false"
      @confirm="enviar()"
    />
    <ConfirmationDialog
      :show="modalJefe"
      title="¿Desea guardar los cambios?"
      message="De confirmar la siguiente acción, se guardarán los cambios y la solicitud será aprobada."
      btnConfirmar="Aceptar"
      @close="modalJefe = false"
      @confirm="enviar()"
    />
    <ConfirmationDialog
      :show="modalEstadoR"
      title="¿Está seguro de rechazar esta solicitud?"
      message="De confirmar la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEstadoR = false"
      @confirm="rechazar()"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import ConfirmationDialog from "../../components/ConfirmationDialogComponent.vue";
import MoneyTextFieldComponent from "../../components/MoneyTextFieldComponent.vue";
export default {
  components: {
    DataTableComponent,
    ConfirmationDialog,
    MoneyTextFieldComponent,
  },
  data: () => ({
    encabezado: [
      {
        text: "No",
        align: "end",
        value: "correlativo",
      },
      {
        text: "OBS",
        align: "start",
        value: "obs",
      },
      {
        text: "Especificación",
        align: "start",
        value: "especificacion",
      },
      {
        text: "Unidad de medida",
        align: "start",
        value: "unidad",
      },
      {
        text: "Cantidad",
        align: "end",
        value: "cantidad",
      },
      {
        text: "Precio unitario ($)",
        align: "center",
        value: "monto",
      },
      {
        text: "Total ($)",
        align: "center",
        value: "total",
      },
      {
        text: "Acciones",
        align: "center",
        value: "acciones",
      },
    ],
    id_compra_linea: null,
    documento: null,
    id_solicitud_compra_linea: null,
    id_detalle: null,
    modalDelete: false,
    modalEstadoA: false,
    modalEstadoR: false,
    id_pago: null,
    pago: null,
    comprobante: null,
    pagos: [],
    loading: false,
    unidadesDeConsolidadora: [],
    modalJefe: false,
    loadingA: false,
    modalEnvio: false,
  }),
  validations: {
    documento: { required },
    id_pago: { required },
    pago: { required },
    comprobante: { required },
  },
  computed: {
    ...mapState("compraLinea", [
      "solicitudDetalle",
      "filters2",
      "compra",
      "infoPago",
    ]),
    ...mapState(["selectedUnidad", "showStats", "userInfo"]),
    pageModel: {
      get() {
        return this.filters2.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters2.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    documentoError() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Documento es requerido");
      return errors;
    },
    formaError() {
      const errors = [];
      if (!this.$v.id_pago.$dirty) return errors;
      !this.$v.id_pago.required && errors.push("Forma de pago es requerido");
      return errors;
    },
    pagoError() {
      const errors = [];
      if (!this.$v.pago.$dirty) return errors;
      !this.$v.pago.required && errors.push("Pago es requerido");
      return errors;
    },
    comprobanteError() {
      const errors = [];
      if (!this.$v.comprobante.$dirty) return errors;
      !this.$v.comprobante.required && errors.push("Comprobante es requerido");
      return errors;
    },
    handleBtnApprove() {
      if (
        this.haveRoles([
          "ROLE_JEFE_UNIDAD",
          "ROLE_JEFE_UNIDAD_CONSOLIDADORA",
        ]) &&
        this.compra.id_estado == 2
      ) {
        // Si soy ucp o ufi y quien la creo es tecnico ufi o tecnico ucp y esta en estado de elaborada
        // Mostrar

        if (this.haveRoles(["ROLE_UACI"])) {
          return this.compra?.id_perfil == 3 || this.compra?.id_perfil == 4;
        }

        if (this.haveRoles(["ROLE_UFI"])) {
          return this.compra?.id_perfil == 5;
        }
        return this.compra?.id_perfil == 9 || this.compra?.id_perfil == 7;

        // Si estado === elaborada y la hizo técnico de unidad y técnico de u consolidadora
        // Mostrar
      }

      if (this.haveRoles(["ROLE_UACI"])) {
        return this.compra?.id_estado == 3;
      }

      if (this.haveRoles(["ROLE_UFI"])) {
        return this.compra?.id_estado == 4;
      }

      return false;
    },
    puedeAgregarInsumos() {
      const unidadUsuario = [
        this.selectedUnidad.id,
        ...this.unidadesDeConsolidadora.map((u) => u.id),
      ];
      const pasosValidos = [1, 2];
      const esPerfilValido = this.haveRoles([
        "ROLE_JEFE_UNIDAD",
        "ROLE_TECNICO_UNIDAD",
        "ROLE_UNIDAD_CONSOLIDADORA",
      ]);

      return (
        unidadUsuario.includes(this.compra.id_unidad) &&
        pasosValidos.includes(Number(this.compra.id_estado)) &&
        esPerfilValido
      );
    },
  },
  methods: {
    ...mapActions("compraLinea", ["getDetalleCompraLinea"]),
    ...mapMutations("compraLinea", ["setCompra", "setInfoPago"]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters2.page = pagina;
      this.filters2.per_page = cantidad_por_pagina;
      this.getDetalleCompraLinea(this.$route.params.id_compra_linea);
    },
    showRestElements() {
      const restos = this.pageModel * this.perPageModel;
      if (restos <= this.filters2.total_rows) return restos;
      else return restos - (restos - this.filters2.total_rows);
    },
    firstElement(value) {
      return this.showRestElements() - value + 1;
    },
    verDetalle(item) {
      this.$router.push({
        name: "editar-insumo-compra-linea",
        params: {
          id_solicitud_compra_linea: this.$route.params.id_compra_linea,
          id_detalle: item.id,
        },
      });
    },
    async getTiposPagos() {
      const { data, status } =
        await this.services.ContratoService.getTiposPagos();
      if (status == 200) this.pagos = data;
    },
    async enviar() {
      this.$v.documento.$touch();

      if (this.solicitudDetalle?.length == 0) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "Debe agregar un insumo a la solicitud",
        });
        return false;
      }


      this.loading = true;
      if (!this.$v.documento.$invalid) {
        const formData = new FormData();
        formData.append("documento", this.documento);

        const response = await this.services.CompraLinea.putCompraLinea(
          this.$route.params.id_compra_linea,
          formData
        ).catch(() => {

          this.loading = false;
        });
        if (response.status === 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Datos guardados",
          });
          if (
            this.haveRoles(
              "ROLE_TECNICO_UNIDAD",
              "ROLE_UACI_TECNICO",
              "ROLE_UFI_TECNICO"
            ) ||
            this.selectedUnidad?.cargo[0]?.id === 7
          ) {
            this.$router.push("/solicitud-compra-linea");
          }
          this.getCompraEnLinea();
          this.getDetalleCompraLinea(this.$route.params.id_compra_linea);
        }

        if (this.haveRole("ROLE_JEFE_UNIDAD")) {
          this.aprobar();
        }
      }

      this.loading = false;
    },
    editarDetalle(item) {
      this.$router.push({
        name: "editar-insumo-compra-linea",
        params: {
          id_solicitud_compra_linea: this.$route.params.id_compra_linea,
          id_detalle: item.id,
        },
      });
    },
    abrirDetalleEliminar(item) {
      this.id_detalle = item.id;
      this.modalDelete = true;
    },
    async eliminarDetalle() {

      const { status } =
        await this.services.CompraLinea.deleteDetalleCompraLinea(
          this.id_solicitud_compra_linea,
          this.id_detalle
        )
      if (status === 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Detalle de la solicitud eliminada",
        });
        this.modalDelete = false;
        this.getDetalleCompraLinea(this.$route.params.id_compra_linea);
      }

    },
    abrirModalEnviar() {
      this.$v.documento.$touch();
      if (!this.$v.documento.$invalid) {
        this.modalEnvio = true;
      }
    },
    abrir() {
      if (this.compra.documento_justificacion) {
        this.$v.$touch();
        
        if (this.haveRole("ROLE_UFI")) {
          if (
            this.$v.id_pago.$invalid ||
            this.$v.pago.$invalid ||
            this.$v.comprobante.$invalid
          ) {
            return false;
          }
        }

        this.modalEstadoA = true;
      } else {
        this.temporalAlert({
          show: true,
          type: "warning",
          message:
            "Debe subir una justificación de compra para poder aprobar está solicitud",
        });
      }
    },
    abrirM() {
      this.modalEstadoR = true;
    },
    abrirModal() {
      this.$v.documento.$touch();
      if (!this.$v.documento.$invalid) {
        this.modalJefe = true;
      }
    },
    async aprobar() {
      this.$v.$touch();

      if (
        this.selectedUnidad.cargo[0].id === 6 ||
        this.selectedUnidad.cargo[0].id === 4 ||
        this.selectedUnidad.cargo[0].id === 19
      ) {
        if (this.compra.id_estado === 2 || this.compra.id_estado === 1) {
          if (this.compra.id_estado !== 4) {

            const { status } = await this.services.CompraLinea.putEstado(
              this.$route.params.id_compra_linea,
              {
                id_estado: 3,
              }
            ).catch(() => {
              if (this.documento === null) {
                this.temporalAlert({
                  show: true,
                  type: "error",
                  message: "Ingresar los datos requeridos",
                });
              }

            });
            if (status === 204) {
              this.temporalAlert({
                show: true,
                type: "success",
                message: "La solicitud fue aprobada",
              });
              this.modalEstadoA = false;

              this.$router.push("/solicitud-compra-linea");
            }
          }
        }
      }

      if (this.selectedUnidad?.cargo[0].id === 11) {
        if (
          this.compra.id_estado === 1 ||
          this.compra.id_estado === 2 ||
          this.compra.id_estado === 3
        ) {

          const { status } = await this.services.CompraLinea.putEstado(
            this.$route.params.id_compra_linea,
            {
              id_estado: 4,
            }
          ).catch(() => {
            if (this.documento === null) {
              this.temporalAlert({
                show: true,
                type: "error",
                message: "Ingresar los datos requeridos",
              });
            }

          });
          if (status === 204) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "La solicitud fue aprobada",
            });
            this.modalEstadoA = false;

            this.$router.push("/solicitud-compra-linea");
          }
        }
      }

      const camposRequeridosUfi =
        Number(this.id_pago) > 0 &&
        Number(this.pago) > 0 &&
        this.comprobante !== null;

      if (
        camposRequeridosUfi &&
        this.haveRole("ROLE_UFI") &&
        this.compra.id_estado === 4
      ) {

        const formData = new FormData();
        formData.append("id_estado", 5);
        formData.append("id_forma_pago", this.id_pago);
        formData.append("pago", Number(this.pago));
        formData.append("comprobando", this.comprobante);
        const { status } = await this.services.CompraLinea.putFinanciamiento(
          this.$route.params.id_compra_linea,
          formData
        ).catch(() => {
          if (
            this.id_pago === null ||
            this.pago === "" ||
            this.comprobante === null
          ) {
            this.temporalAlert({
              show: true,
              type: "error",
              message: "Ingresar los datos requeridos",
            });
          }

        });
        if (status === 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La solicitud fue aprobada",
          });
          this.modalEstadoA = false;

          this.$router.push("/solicitud-compra-linea");
        }
      }

      this.modalEstadoA = false;
    },
    async rechazar() {

      if (
        this.selectedUnidad?.cargo[0].id === 6 ||
        this.selectedUnidad.cargo[0].id === 19
      ) {
        const { status } = await this.services.CompraLinea.putEstado(
          this.$route.params.id_compra_linea,
          {
            id_estado: 9,
          }
        )
        if (status === 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La solicitud fue rechazada",
          });
          this.modalEstadoR = false;
          this.$router.back();
        }
      } else if (this.selectedUnidad?.cargo[0].id === 11) {
        const { status } = await this.services.CompraLinea.putEstado(
          this.$route.params.id_compra_linea,
          {
            id_estado: 11,
          }
        )
        if (status === 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La solicitud fue rechazada",
          });
          this.modalEstadoR = false;
          this.$router.back();
        }
      } else {
        const { status } = await this.services.CompraLinea.putEstado(
          this.$route.params.id_compra_linea,
          {
            id_estado: 10,
          }
        )
        if (status === 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La solicitud fue rechazada",
          });
          this.modalEstadoR = false;
          this.$router.back();
        }
      }

    },
    async getCompraEnLinea() {

      const response = await this.services.CompraLinea.getCompraEnLinea(
        this.id_solicitud_compra_linea
      );
      if (response?.status == 200) this.setCompra(response.data);

    },
    async descargarArchivo(ruta) {

      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: ruta,
          disk: "procesos",
        })
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], ruta.split("/").pop(), {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
    async getUnidadesDependientes() {

      const { data, status } =
        await this.services.UnidadesServices.getUnidadesDependientesConsolidadora(
          this.selectedUnidad.id
        );

      if (status === 200) this.unidadesDeConsolidadora = data;

    },
    formatPago() {
      if (!/^[0-9 .,]*$/.test(this.pago)) this.pago = "";
      if (this.pago) this.pago = Number(this.pago).toFixed(2);
    },
  },
  watch: {
    infoPago() {
      if (this.infoPago) {
        this.id_pago = this.infoPago.id_metodo_pago;
        this.pago = this.infoPago.pago;
      }
    },
  },
  async created() {
    this.setInfoPago(null);
    this.id_solicitud_compra_linea = Number(this.$route.params.id_compra_linea);
    await this.getCompraEnLinea();
    await this.getDetalleCompraLinea(this.$route.params.id_compra_linea);
    await this.getTiposPagos();
    if (this.selectedUnidad.id_tipo_unidad === 4)
      await this.getUnidadesDependientes();
  },
};
</script>

<style></style>
