<template>
  <section class="mx-3">
    <!-- titulo -->
    <div class="my-6 d-flex flex-column text-center text-sm-left">
      <span
        class="text-h5 secondary--text"
        v-text="'Solicitudes de compra en línea'"
      />
      <span class="" v-text="'Ejercicio ' + anioFiscal" />
    </div>
    <!-- ---- -->
    <v-row class="justify-center" v-if="showStats">
      <v-card
      color="bgMinsal"
      elevation="0"
      v-for="item in totales"
      :key="item.id"
      class="mx-11 mt-8"
    >
      <v-card-title class="d-flex flex-column justify-center">
        <p class="text-h4">{{ item.cantidad }}</p>
        <p class="mt-n6 subtitle-1 text-center text-wrap">{{ item.estado }}</p>
      </v-card-title>
      <v-progress-linear
        :color="item.color_estado"
        rounded
        value="100"
        class="mt-n8"
      />
    </v-card> 
  </v-row>
    <v-row class="mt-8 mb-8" v-if="showSolicitudButton || haveRoles(['ROLE_UACI', 'ROLE_UFI', 'ROLE_JEFE_UNIDAD'])">
      <v-col cols="12" sm="3" md="2">
        <v-btn
          color="secondary white--text"
          block
          v-if="selectedUnidad?.id_tipo_unidad !== 4 "
          @click="addSolicitudCompra()"
          >Agregar solicitud</v-btn
        >
        <v-btn v-if="selectedUnidad?.id_tipo_unidad === 4" color="secondary white--text" block @click="abrir()"
          >Agregar solicitud</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" sm="12" md="4" v-if="selectedUnidad.cargo[0]?.id === 7 || selectedUnidad.cargo[0]?.id === 19">
        <v-select
         label="Unidad solicitante"
         outlined
         v-model="filters.id_unidad"
         :items="unidades"
         item-value="id"
         item-text="nombre"
         @change="unidadFilter(filters.id_unidad)"
         clearable
         @click:clear="
                setFiltroUnidad(null),
                  getSolicitudes()
              "
        ></v-select>
      </v-col>
      
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menuRangoFechas"
          v-model="menuRangoFechas"
          :close-on-content-click="false"
          :return-value.sync="fechas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              :value="rangoFechasTexto"
              label="Desde - Hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="
                setFiltroFecha({ fecha_desde: null, fecha_hasta: null }),
                  getSolicitudes()
              "
            />
          </template>
          <v-date-picker
            title="es"
            v-model="fechas"
            range
            locale="es-Es"
            @click:date="checkRange"
            :max="fechaActual"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="(menuRangoFechas = false), (fechas = [])"
            >
              Cancelar
            </v-btn>
            <v-btn
              :loading="deshabilitarBoton"
              :disabled="deshabilitarBoton"
              text
              color="primary"
              @click="$refs.menuRangoFechas.save(fechas), setFecha()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-select
          label="Estado"
          outlined
          :items="pasosSolicitudes"
          item-value="id"
          item-text="nombre"
          clearable
          v-model="filters.id_estado"
          @change="estado(filters.id_estado)"
          @click:clear="
                setFiltroEstado(null),
                  getSolicitudes()
              "
        ></v-select>
      </v-col>
    </v-row>

    <v-col cols="3">
      <p class="text-h6 font-museo-sans primary--text">
        Listado de solicitudes
      </p>
    </v-col>

    <data-table-component
      :headers="encabezado"
      :items="solicitudes"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters.total_rows"
      @paginar="paginar"
    >
      <template #[`item.tipo_compra`]="{ item }">
        Compra en línea
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ item.created_at? moment(item.created_at).format("DD/MM/YYYY hh:mm A") : '' }}
      </template>
      <template #[`item.estado`]="{ item }">
        <v-chip :color="item.color_estado" label
        :dark="
            item.estado.includes('Cancelado') ||
            item.estado.includes('Aprobado por')
          ">
          {{  item.estado }}</v-chip>
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-row class="ma-0 pa-0 pl-5" style="width: 80%">
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                @click="verDetalle(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span> Ver </span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon
                v-if="item.estado === 'En borrador'"
                @click="abrirDelete(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar solicitud </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </data-table-component>

    <v-dialog v-model="modal" max-width="50vh" @click:outside="modal = false">
      <v-card>
        <v-container>
          <span class="text-h6 secondary--text">Crear solicitud</span>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                 outlined
                 label="Unidad solicitante *"
                 v-model="form.id_unidad"
                 item-text="nombre"
                 item-value="id"
                 :items="unidades"
                 @blur="$v.form.id_unidad.$touch()"
                 :error-messages="unidadError"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="d-flex d-inline-flex justify-space-around">
              <v-btn outlined color="secondary" @click="modal = false" class="mr-4">Cancelar</v-btn>
              <v-btn color="secondary" @click="guardar()" class="ml-4">Crear solicitud</v-btn>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <ConfirmationDialog 
     :show="modalDelete"
     title="¿Está seguro de eliminar esta solicitud?"
     message="De confirmar la siguiente acción los cambios no serán reversibles"
     btnConfirmar="Aceptar"
     @close="modalDelete = false"
     @confirm="eliminarSolicitud()"
    />
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import ConfirmationDialog from "../../components/ConfirmationDialogComponent.vue";
import moment from "moment";
import StatsComponent from '../../components/StatsComponent.vue';
export default {
  components: { DataTableComponent, ConfirmationDialog, StatsComponent },
  data: () => ({
    encabezado: [
      {
        text: "Correlativo",
        value: "correlativo",
      },
      {
        text: "Unidad solicitante",
        value: "unidad",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        align: "center",
      },
      {
        text: "Tipo de compra",
        value: "tipo_compra",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    pasosSolicitudes: [],
    modal:false,
    form:{
      id_unidad:null,
    },
    unidades:[],
    menuRangoFechas: false,
    fechas: ["", ""],
    fechaActual: moment().format("YYYY-MM-DD"),
    deshabilitarBoton: false,
    id_estado:null,
    id_compra_linea:null,
    modalDelete:false,
    itemFondos:[]
  }),
  validations:{
    form:{
      id_unidad: { required }
    }
  },
  computed: {
    ...mapState(["anioFiscal", "selectedUnidad"]),
    ...mapGetters(["showStats", "showSolicitudButton"]),
    ...mapState("compraLinea", ["solicitudes","filters", "totales"]),
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    unidadError(){
      const errors = []
      if (!this.$v.form.id_unidad.$dirty) return errors;
      !this.$v.form.id_unidad.required && 
        errors.push("Unidad solicitante es obligatorio")
      return errors
    },
    rangoFechasTexto() {
      if (this.fechas[0] == "" && this.fechas[1] == "") {
        return "";
      } else {
        return (
          moment(this.filters.fecha_desde).format("D/M/Y") +
          " - " +
          moment(this.filters.fecha_hasta).format("D/M/Y")
        );
      }
    },
  },
  methods: {
    ...mapActions("compraLinea", ["getSolicitudes"]),
    ...mapMutations("compraLinea", ["setFiltroFecha", "setFiltroEstado", "setFiltroUnidad", "setCompra"]),
    checkRange(val) {
      if (this.fechas[0] > this.fechas[1]) {
        this.fechas.splice(0, 0, val);
      }
    },
    async getPasosSolicitudes() {
      const { data, status } =
        await this.services.SolicitudCompra.getCatalogoPasos();

      if (status === 200) {
        if (this.haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO'])) {
          this.pasosSolicitudes = data.filter(item => [1, 2, 3, 5, 4, 10, 11].includes(item.id));
        } else if (this.haveRoles(['ROLE_UFI', 'ROLE_UFI_TECNICO'])) {
          this.pasosSolicitudes = data.filter(item => [1, 2, 3, 5, 4, 9, 10].includes(item.id));
        } else {
          this.pasosSolicitudes = data.filter(item => [1, 2, 3, 4, 9, 11, 5, 10].includes(item.id));
        }
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getSolicitudes();
    },
    setFecha(){
      if(this.fechas[0] !== null && this.fechas[1] !== null){
        this.setFiltroFecha({
          fecha_desde: this.fechas[0],
          fecha_hasta: this.fechas[1]
        })
      }
      this.getSolicitudes()
    },
    
    estado(value){
      this.setFiltroEstado(value)
      this.getSolicitudes()
    },
    unidadFilter(value){
      this.setFiltroUnidad(value)
      this.getSolicitudes()
    },
    async addSolicitudCompra() {
      let body = {
        id_unidad: this.selectedUnidad?.id,
      };

      const { data, status } =
        await this.services.CompraLinea.postSolicitudCompraLinea(body);
      if (status === 201) {
        this.$router.push({
          name: "crear-compra-linea",
          params: {
            id_compra_linea: data.id,
          },
        });
        // this.setIdSolicitud(data.id);
      }
    },
    verDetalle(item){
      this.$router.push(`/solicitud-compra-linea/${item.id}`)
      this.setCompra(item)
    },
    abrir(){
      this.modal = true
    },
    async getUnidadesDependientes() {
      const { data, status } = await this.services.UnidadesServices.getUnidadesDependientesConsolidadora(
        this.selectedUnidad.id
      )

      if(status === 200){
        this.unidades = data
      }
    },
    async guardar(){
      let body = {
        id_unidad: this.form.id_unidad
      };

      const { data, status } =
        await this.services.CompraLinea.postSolicitudCompraLinea(body);
      if (status === 201) {
        this.$router.push({
          name: "crear-compra-linea",
          params: {
            id_compra_linea: data.id,
          },
        });
        // this.setIdSolicitud(data.id);
      }
    },
    abrirDelete(item){
      this.id_compra_linea = item.id
      this.modalDelete = true
    },
    async eliminarSolicitud(){

      const { status } = await this.services.CompraLinea.deleteSolicitudCompra(
        this.id_compra_linea
      )
      if(status === 204) {
        this.temporalAlert({
          show:true,
          type:"success",
          message: "La solicitud fue eliminada con éxito"
        })
        this.modalDelete = false
        this.getSolicitudes()
      }
    }
  },
  async created() {
    await this.getSolicitudes();
    await this.getPasosSolicitudes();
    this.getUnidadesDependientes()
  },
  beforeDestroy(){
    this.setFiltroEstado(null)
    this.setFiltroFecha({
      fecha_desde: null,
      fecha_hasta: null
    })
    this.setFiltroUnidad(null)
  }
};
</script>

<style></style>
