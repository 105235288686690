<template>
  <section class="my-8">
    <v-row justify="end">
      <v-col cols="12" sm="4" md="3" v-if="showUnidadFilter">
        <v-select
          :items="unidadesList"
          label="Unidad Solicitante"
          item-value="id"
          item-text="nombre"
          outlined
          clearable
          :value="filters.id_unidad"
          @change="setUnidadSolicitante"
          @click:clear="setUnidadesFilter(null)"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                  {{ item.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle>
                  {{ item.institucion }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="searchByNumber"
            append-icon="mdi-magnify"
            label="Buscar por código"
            single-line
            outlined
            hide-details
            @keyup="setCorrelativoBusqueda"
            @click:clear="setCorrelativoFilter(null), getSolicitudesCompra()"
            clearable
          ></v-text-field>
        </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-menu
          ref="menuRangoFechas"
          v-model="menuRangoFechas"
          :close-on-content-click="false"
          :return-value.sync="fechas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              :value="rangoFechasTexto"
              label="Desde - Hasta"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="
                setFechasFilter({ fecha_desde: null, fecha_hasta: null }),
                  getSolicitudesCompra()
              "
            />
          </template>
          <v-date-picker
            title="es"
            v-model="fechas"
            range
            locale="es-Es"
            @click:date="checkRange"
            :max="fechaActual"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="(menuRangoFechas = false), (fechas = [])"
            >
              Cancelar
            </v-btn>
            <v-btn
              :loading="deshabilitarBoton"
              :disabled="deshabilitarBoton"
              text
              color="primary"
              @click="$refs.menuRangoFechas.save(fechas), setFechas()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="pasosSolicitudes"
          label="Estado"
          item-value="id"
          item-text="nombre"
          outlined
          :value="filters.id_estado"
          clearable
          @change="setEstadoCompra"
          @click:clear="setEstadoCompraFilter(null)"
        />
      </v-col>
    </v-row>
    <div class="pa-8 mt-3 mb-5" v-if="haveRoles(['ROLE_AUDITORIA_GLOBAL'])">
      <v-row>
        <v-col cols="6">
            <v-autocomplete
              label="Institución"
              :items="instituciones"
              item-value="id"
              item-text="nombre_completo"
              outlined
              hide-details
              clearable
              v-model="filters.id_institucion"
            />
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              color="secondary"
              class="flex-shrink-1 flex-grow-1"
              style="width: 100%;"
              @click="setInstitucionFilter(null), getSolicitudesCompra()"
              x-large
            >
              Busqueda por institución
            </v-btn>
          </v-col>
      </v-row>
    </div>

    <p class="text-h6 font-museo-sans primary--text">Listado de solicitudes</p>

    <data-table-component
      :headers="encabezados"
      :items="solicitudesCompraList"
      v-models:select="perPageModel"
      v-models:pagina="pageModel"
      :total_registros="filters.total_rows"
      @paginar="paginar"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY hh:mm A") }}
      </template>
      <template v-slot:[`item.paso`]="{ item }">
        <v-chip
          label
          :dark="
            item.paso.includes('Cancelado') ||
            item.paso.includes('Aprobado por')
          "
          :color="item.color_paso"
        >
          {{ item.paso || "" }}
        </v-chip>
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-row class="ma-0 pa-0 pl-5" style="width: 80%;">
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="getSolicitudBitacora(item)"
                >
                  <v-icon>mdi-file</v-icon>
                </v-btn>
              </template>
              <span>Ver histórico de solicitud</span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="
                    hideShowButtonUACI(item) ||
                    hideShowButtonJefe(item) ||
                    hideShowButtonCancelled(item)
                  "
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="goToListInsumos(item)"
                  :data-id-solicitud="item.id"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span> Ver </span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0">
            <v-tooltip right v-if="item.id_paso === 1 && !haveRoles(['ROLE_OFICIAL_CUMPLIMIENTO', 'ROLE_AUDITOR_INTERNO_AUIN'])">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="(itemToDelete = item.id), (modalEliminar = true)"
                  :data-id-solicitud="item.id"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> Eliminar </span>
            </v-tooltip>
          </v-col>
        </v-row>

      </template>
    </data-table-component>
    <dialogoConfirmacion
      :show="modalEliminar"
      title="¿Desea cancelar la solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminar = false"
      @confirm="eliminarSolicitud()"
    />
    <v-dialog v-model="showModalBitacora" persistent max-width="1200">
      <v-card class="pa-4" max-width="1200">
        <div class="headerIcon">
          <v-icon color="secondary" @click="close"> mdi-close </v-icon>
        </div>
        <v-row>
          <v-col cols="12">
            <p class="text-h5 text-center px-4">
              {{ "Histórico de solicitud" }}
            </p>
          </v-col>
        </v-row>
        <v-card-text class="text-center">
          <expansion-panel-bitacora :bitacora="bitacora" />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="descargarBitacora()">Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions, mapMutations } from "vuex";
import DataTableComponent from "@/components/DataTableComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import ExpansionPanelBitacora from "../components/ExpansionPanelBitacoraComponent.vue";
import SolicitudCompra from "../../../services/SolicitudCompra.services";
import { getSolicitudesCompra } from "../store/solicitudCompra/actions";

export default {
  name: "ListadoSolicitudesComponent",
  components: {
    DataTableComponent,
    dialogoConfirmacion,
    ExpansionPanelBitacora,
  },
  data() {
    return {
      id_solicitud: null,
      modalEliminar: false,
      tiposCompra: [],
      pasosSolicitudes: [],
      unidadesList: [],
      deshabilitarBoton: false,
      fechas: ["", ""],
      fechaActual: moment().format("YYYY-MM-DD"),
      menuRangoFechas: false,
      encabezados: [
        {
          text: "Correlativo",
          value: "codigo",
          align: "center",
        },
        {
          text: "Unidad solicitante",
          value: "unidad",
          align: "center",
        },
        {
          text: "Fecha de creación",
          value: "created_at",
          align: "center",
        },
        {
          text: "Estado",
          value: "paso",
          align: "center",
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          width:"15em",
          sortable: false,
        },
      ],
      solicitudes: [],
      itemToDelete: null,
      showModalBitacora: false,
      searchByNumber: null,
      ctlInstituciones: [],
    };
  },
  computed: {
    ...mapState("comprasModule", [
      "solicitudesCompraList",
      "filters",
      "bitacora",
    ]),
    ...mapState(["selectedUnidad", "cargos"]),
    cargoSeleccionado() {
      if (this.selectedUnidad && this.selectedUnidad?.cargo) {
        return this.selectedUnidad?.cargo[0].id;
      } else return 0;
    },
    pageModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    rangoFechasTexto() {
      if (this.filters.fecha_desde == null && this.filters.fecha_hasta == null) {
        return "";
      } else {
        return (
          moment(this.filters.fecha_desde).format("D/M/Y") +
          " - " +
          moment(this.filters.fecha_hasta).format("D/M/Y")
        );
      }
    },
    showUnidadFilter() {
      return (
        this.cargoSeleccionado == this.cargos.jefe_ufi ||
        this.cargoSeleccionado == this.cargos.jefe_uaci
      );
    },
    instituciones() {
      return this.ctlInstituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }))
    },
  },
  methods: {
    ...mapActions("comprasModule", [
      "getSolicitudesCompra",
      "getBitacora",
      "getDocumentoBitacora",
    ]),
    ...mapMutations("comprasModule", [
      "setPage",
      "setPerPage",
      "setTipoCompraFilter",
      "setEstadoCompraFilter",
      "setInstitucionSelectedFilter",
      "setFechasFilter",
      "setUnidadesFilter",
      "setPasoSolicitud",
      "setCorrelativoFilter",
      "setCorrelativoSolicitud",
    ]),
    ...mapMutations("agregarInsumo", ["setIdSolicitud"]),
    checkRange(val) {
      if (this.fechas[0] > this.fechas[1]) {
        this.fechas.splice(0, 0, val);
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getSolicitudesCompra();
    },
    setTipoCompra(value) {
      this.setTipoCompraFilter(value);
      this.getSolicitudesCompra();
    },
    setEstadoCompra(value) {
      this.setEstadoCompraFilter(value);
      this.getSolicitudesCompra();
    },
    setInstitucionFilter() {
      const value = this.filters.id_institucion;
      this.setInstitucionSelectedFilter(value);
      this.getSolicitudesCompra();
    },
    setFechas() {
      this.setFechasFilter({
        fecha_desde: this.fechas[0],
        fecha_hasta: this.fechas[1],
      });
      this.getSolicitudesCompra();
    },
    setUnidadSolicitante(value) {
      this.setUnidadesFilter(value);
      this.getSolicitudesCompra();
    },
    setCorrelativoBusqueda() {
      const value = this.searchByNumber;
      this.setCorrelativoFilter(value);
      this.getSolicitudesCompra();
    },
    async getTiposCompra() {
      const { data, status } =
        await this.services.SolicitudCompra.getTiposSolicitudesCompra();

      if (status === 200) {
        this.tiposCompra = data;
      }
    },
    async getPasosSolicitudes() {
      const { data, status } =
        await this.services.SolicitudCompra.getCatalogoPasos();

      if (status === 200) {
        if (this.haveRoles(['ROLE_UFI_TECNICO', 'ROLE_UACI_TECNICO', 'ROLE_TECNICO_UNIDAD'])) {
          this.pasosSolicitudes = data.filter(item => [1, 2, 3, 8].includes(item.id)); // En borrador, elaborada, aprobada, con observación
        } else {
          this.pasosSolicitudes = data.filter(item => [2,3,8].includes(item.id)); // Elaborada, aprobada, observada
        }
      }
    },
    async getUnidadesList() {
      const { data, status } = await this.services.UnidadesServices.getUnidadesInstitucionUnidadesDenominadas();

      if (status == 200) {
        this.unidadesList = data;
      }
    },
    goToListInsumos(item) {
      this.setIdSolicitud(item.id);
      this.setPasoSolicitud(item.paso);
      this.setCorrelativoSolicitud(item.codigo);

      if (this.redirectToUACIContrato(item)) {
        this.$router.push({
          name: "lista-ganadores",
          params: { tipo: Number(item.id_tipo_solicitud) },
        });
      } else {
        if(Number(item.id_tipo_solicitud) === 1){
          this.$router.push({
            name: "list-obs-proceso-tradicional",
            params: {
              id_solicitud: item.id
            }
          });
        } else {
          this.$router.push({
            name: "compras",
            params: {
              tipo: Number(item.id_tipo_solicitud),
              id_solicitud: item.id,
              id_estado_solicitud: item.id_paso
            },
          });
        }
      }
    },
    redirectToUACIContrato(item) {
      return (
        (this.cargoSeleccionado == this.cargos.jefe_uaci ||
          this.cargoSeleccionado == this.cargos.director) &&
        item.id_paso == 5
      );
    },
    async eliminarSolicitud() {
      const { status } = await this.services.SolicitudCompra.deleteSolicitud(
        this.itemToDelete
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "La solicitud ha sido cancelada",
          type: "success",
        });
        this.modalEliminar = false;
        this.getSolicitudesCompra();
      }
    },

    /**
     * Ocultar botón de mostrar para jefe uaci si las solicitudes solo están
     * aprobadas por unidad y no por ufi todavía
     * @param {*} item
     */
    hideShowButtonUACI(item) {
      if (item.id_tipo_solicitud == 2 || item.id_tipo_solicitud == 3) {
        return (
          item.id_paso == 3 && this.cargoSeleccionado == this.cargos.jefe_uaci
        );
      }
      return (
        this.cargoSeleccionado == this.cargos.jefe_uaci &&
        (item.id_paso == 3 || item.id_paso == 5) &&
        item.id_tipo_solicitud == 1
      );
    },

    hideShowButtonUFI(item) {
      return (
        (this.cargoSeleccionado == this.cargos.jefe_ufi ||
          this.cargoSeleccionado == this.cargos.tecnico_ufi) &&
        item.id_paso == 5
      );
    },

    hideShowButtonJefe(item) {
      return (
        this.cargoSeleccionado == this.cargos.jefe_unidad_solicitante &&
        item.id_paso == 12 //observado por UCP
      );
    },
    hideShowButtonCancelled(item) {
      return (
        item.id_paso == 10 || item.id_paso == 9 || item.id_paso == 11 //cancelado ya sea por UCP,Unidad o UFI
      );
    },
    async getSolicitudBitacora(item) {
      let params = {
        id_solicitud: item.id,
      };
      const response = await this.getBitacora(params);
      if (response.status === 200) {
        this.id_solicitud = item.id;
        this.showModalBitacora = true;
      }
    },
    async descargarBitacora() {
      if (this.id_solicitud !== null) {
        let params = {
          id_solicitud: this.id_solicitud,
        };
        const response = await this.getDocumentoBitacora(params);
        if (!response) {
          this.temporalAlert({
            show: true,
            message: "Ocurrió un error al descargar el documento",
            type: "error",
          });
        } else {
          const fileUrl = window.URL.createObjectURL(new Blob([response]));
          const fileLink = document.createElement("a");
          const time = new Date().getTime() / 1000;
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", `historico-solicitud-${time}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      }
    },
    close() {
      this.showModalBitacora = false;
    },
    async filterByNumber() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getSolicitudesCompra();
      }, 500);
    },
    async fetchInstituciones() {
      const response =
          await this.services.Instituciones.getCatalogoInstituciones({
            pagination: false,
          });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
  },
  created() {
    // this.filters.id_unidad = this.selectedUnidad.id;
    this.fetchInstituciones();
    this.getSolicitudesCompra();
    this.getTiposCompra();
    this.getPasosSolicitudes();
    this.getUnidadesList();
    this.setFechasFilter({
      fecha_desde:null,
      fecha_hasta:null
    })
  },
  beforeDestroy(){
    this.setTipoCompraFilter()
    this.setUnidadesFilter()
    this.setEstadoCompraFilter()
    this.setFechasFilter({
      fecha_desde:null,
      fecha_hasta:null
    })
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}
</style>
