<template>
  <section fluid>
    <p class="text-h6 font-museo-sans mb-0 secondary--text">
      Solicitudes de obras, bienes o servicios
    </p>
    <p class="text-body-2 font-museo-sans primary--text">
      Ejercicio {{ anioFiscal }}
    </p>
    <v-row class="mt-8 mb-8" v-if="showSolicitudButton">
      <v-col cols="12" sm="3" md="2">
        <router-link :to="{ name: 'crear-solicitud-compra' }" v-if="selectedUnidad.cargo[0].id !== 7 && !haveRole('ROLE_UNIDAD_CONSOLIDADORA')"
          ><v-btn color="secondary white--text" block
            >Agregar solicitud</v-btn
          ></router-link
        >
        <v-btn v-else color="secondary white--text" block @click="abrirModal()"
          >Agregar solicitud</v-btn
        >
      </v-col>
    </v-row>
    <ListadoSolicitudesComponent />

    <v-dialog v-model="modal" max-width="50vh" @click:outside="modal = false">
      <v-card>
        <section>
          <span class="text-h6 secondary--text">Crear solicitud</span>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                 outlined
                 label="Unidad solicitante *"
                 v-model="form.id_unidad"
                 item-text="nombre"
                 item-value="id"
                 :items="unidades"
                 @change="getProcesosUnidad()"
                 @blur="$v.form.id_unidad.$touch()"
                 :error-messages="unidadError"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                 outlined
                 label="Proceso de compra *"
                 v-model="form.id_proceso_pac"
                 item-text="nombre"
                 item-value="id"
                 :items="procesos"
                 :disabled="form.id_unidad === null"
                 @blur="$v.form.id_proceso_pac.$touch()"
                 :error-messages="procesoError"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="d-flex d-inline-flex justify-space-around">
              <v-btn outlined color="secondary" @click="modal = false" class="mr-4">Cancelar</v-btn>
              <v-btn color="secondary" @click="guardar()" class="ml-4">Crear solicitud</v-btn>
            </v-row>
          </v-card-text>
        </section>
      </v-card>
    </v-dialog>
  </section>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.v-application .text-body-2{
  font-family: "MuseoSans", sans-serif !important;
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import ListadoSolicitudesComponent from "./components/ListadoSolicitudesComponent.vue";
import StatsComponent from "../../components/StatsComponent.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "solicitudesCompraView",
  components: {
    ListadoSolicitudesComponent,
    StatsComponent,
  },
  props: {},
  data: () => ({
    itemsFondos: [
      {
        id: 1,
        value: "0",
        title: "Aprobadas",
        color: "green accent-3",
      },
      {
        id: 2,
        value: "1",
        title: "Pendientes",
        color: "yellow lighten-1",
      },
      {
        id: 3,
        value: "10",
        title: "Con observaciones",
        color: "green accent-3",
      },
    ],
    modal:false,
    unidades:[],
    procesos:[],
    form: {
      id_unidad:null,
      id_proceso_pac:null,
      id_solicitud_tipo:1
    },
  }),
  validations:{
    form:{
      id_proceso_pac: {required},
      id_unidad: {required}
    }
  },
  computed: {
    ...mapState(["userInfo", "anioFiscal", "selectedUnidad"]),
    ...mapGetters(["showStats", "showSolicitudButton"]),
    unidadError(){
      const errors = []
      if (!this.$v.form.id_unidad.$dirty) return errors;
      !this.$v.form.id_unidad.required && 
        errors.push("Unidad solicitante es obligatorio")
      return errors
    },
    procesoError(){
      const errors = []
      if (!this.$v.form.id_proceso_pac.$dirty) return errors;
      !this.$v.form.id_proceso_pac.required && 
        errors.push("Proceso de compra es obligatorio")
      return errors
    }
  },
  watch: {
    selectedUnidad() {
      if (this.showStats) this.getConsolidados();
    },
  },
  methods: {
    async getConsolidados() {
      const { status, data } =
        await this.services.SolicitudCompra.getConsolidadosSolicitudCompra({
          anio: this.anioFiscal,
        });

      if (status == 200) {
        this.itemsFondos = data;
      }
    },
    abrirModal(){
      this.modal = true
    },
    async guardar(){
      this.$v.$touch();
      if (!this.$v.$invalid) {

        const response = await this.services.SolicitudCompra.postSolicitudCompra(
          this.form
        )
        if(response.status === 201){
          this.modal = false
            this.$router.push({
            name: "list-obs-proceso-tradicional",
            params:{
              id_solicitud: response.data.id
            }
          })
        }
        
      }
    },
    async getUnidadesDependientes() {
      const { data, status } = await this.services.UnidadesServices.getUnidadesDependientesConsolidadora(
        this.selectedUnidad.id
      )

      if(status === 200){
        this.unidades = data
      }
    },
    async getProcesosUnidad(){
      const { data, status } = await this.services.SolicitudCompra.getCtlProcesoCompra({
        id_unidad : this.form.id_unidad
      })

      if(status == 200) {
        this.procesos = data
      }
    }
  },
  mounted() {},
  created() {
    if (this.showStats) this.getConsolidados();
    this.getUnidadesDependientes()
  },
};
</script>
